import styled from "@emotion/styled"
import * as React from "react"
import {Layout} from "../components/Layout"
import notFound from "../data/images/404.svg"


const WhiteBg = styled.div(({theme}) => ({
  backgroundColor: "white",
  paddingTop: "3em",
  textAlign: "center",
  height: "100%",
  overflow: "hidden",
  img: {
    width: "30%"
  }
}))

const NotFoundPage = () => {

  return (
    <Layout title={"Recalbox Home"}>
      <WhiteBg>
        <img src={notFound} alt="404"/>
      </WhiteBg>
    </Layout>
  )
}

export default NotFoundPage

